import React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import Container from '../components/container';
import { Link } from 'gatsby';

class ThankyouPage extends React.Component {
  render() {

    return (
      <Layout location={this.props.location}>
        <Seo title="Kontakt" />
        <Container>
        <h1>Tack!</h1>
        <p>Tack för ditt meddelande, styrelsen hanterar ärendet.</p>
        <Link to="/kontakt/">
          Tillbaka
        </Link>
        </Container>
      </Layout>
    )
  }
}

export default ThankyouPage;
